import { urlApiAnalytic } from '~/utils/constants';
import axiosClient from './axiosClient';
import { IQueryGetTables } from './table';

const totalPointTeamApi = {
  getTeam(payload: IQueryGetTables) {
    const { tournamentId, groupName } = payload;
    let url = `${urlApiAnalytic.getTotalPointTeam}=${tournamentId}`;
    if (groupName) {
      url += `&groupName=${groupName}`;
    }
    return axiosClient.get(url);
  },
};

export default totalPointTeamApi;
