// Libs
import { Button, Form, Input, Layout, message as toast } from 'antd';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
import { login } from '~/thunks/auth/authThunk';
// Thunks
import { authActions } from '~/thunks/auth/authSlice';
// Shares
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingContext } from '~/contexts/LoadingContext';
import { LoginPayload } from '~/data/auth';
import { ExactPath, StorageConstants } from '~/utils/enum';
import { handleResponseError, validateEmail } from '~/utils/helper';
import styles from './Login.module.scss';
import { image } from '~/utils/constants.asset';
import i18n from '~/utils/i18n';
import { language } from '~/utils/constants';
const cx = classNames.bind(styles);

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const loadingContext = useContext(LoadingContext);

  /**
   * UseEffect Block
   */

  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);
  const accessToken = localStorage.getItem(StorageConstants.ACCESS_TOKEN)!;

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  useEffect(() => {
    if (accessToken) {
      navigate(ExactPath.main);
    } else {
      dispatch(authActions.handleLogout());
    }
  }, [navigate]);

  /**
   * Functions Block
   */

  const handleLogin = (values: LoginPayload) => {
    loadingContext?.show();
    dispatch(login(values))
      .unwrap()
      .then((resp) => {
        loadingContext?.hide();
        const { user, accessToken } = resp.data;
        if (accessToken) {
          i18n.changeLanguage(language.IT);
          toast.success(t('login_success', { email: user?.email ? user?.email : 'Admin' }));
          navigate(ExactPath.main);
        }
      })
      .catch((err) => {
        loadingContext?.hide();
        handleResponseError(err, 'login_error', t);
        dispatch(authActions.handleLogout());
      });
  };

  return (
    <>
      {!accessToken && (
        <Layout className={cx('main_layout')}>
          <div className={cx('layout_login_left')}>
            <img src={image.backgroundLogin} alt='img-bg-football' />
          </div>
          <div className={cx('login_form')}>
            <Form name='basic' form={form} onFinish={handleLogin} layout='vertical'>
              <Form.Item>
                <h1>{t('welcome_to')}</h1>
              </Form.Item>
              <Form.Item
                label={t('label_email')}
                name='email'
                rules={[{ required: true, message: t('message_required_email') }, validateEmail(t)]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t('label_password')}
                name='password'
                rules={[{ required: true, message: t('message_required_password') }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={cx('btn_login')}
                  disabled={!submittable}
                >
                  {t('submit')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Login;
