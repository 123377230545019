import { createSlice } from '@reduxjs/toolkit';
import { getRound, getRoundsSelection } from './roundThunk';

interface RoundState {
  listRound: Array<any> | null | undefined;
  roundsSelection: Array<any> | null | undefined;
}

const initialState: RoundState = {
  listRound: [],
  roundsSelection: [],
};

const roundSlice = createSlice({
  name: 'round',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRound.fulfilled, (state, action) => {
      state.listRound = action.payload.data.rounds;
    });
    builder.addCase(getRoundsSelection.fulfilled, (state, action) => {
      state.roundsSelection = action.payload.data.rounds;
    });
  },
});

export const roundActions = roundSlice.actions;

export const selectListRound = (state: any) => state.round.listRound;
export const selectRoundSelection = (state: any) => state.round.roundsSelection;

const roundReducer = roundSlice.reducer;
export default roundReducer;
