import { BarChartOutlined, SyncOutlined, TableOutlined } from '@ant-design/icons';
import { Menu, MenuProps, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AdminProtectedPath, RouterPaths } from '~/utils/enum';
import { getSideNav } from '~/utils/helper';
import styles from './SideBar.module.scss';

import { icon } from '~/utils/constants.asset';

const cx = classNames.bind(styles);

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const locations = useLocation().pathname.split('/');
  const { t } = useTranslation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    if (locations.includes('analytics')) {
      return ['analytics'];
    }
    return [];
  });

  const items: MenuItem[] = [
    getItem(
      <Link to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ROUND.path}>{t('round_title')}</Link>,
      AdminProtectedPath.ROUND.key,
      <SyncOutlined />
    ),
    getItem(
      <Link to={'/' + RouterPaths.ADMIN + AdminProtectedPath.TABLE.path}>{t('table_title')}</Link>,
      AdminProtectedPath.TABLE.key,
      <TableOutlined />
    ),
    getItem(t('analytic_title'), 'analytics', <BarChartOutlined />, [
      getItem(
        <Link to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ANALYTIC_TEAM.path}>
          {t('sidebar_team_title')}
        </Link>,
        AdminProtectedPath.ANALYTIC_TEAM.key
      ),
      getItem(
        <Link to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ANALYTIC_PLAYER.path}>
          {t('sidebar_player_title')}
        </Link>,
        AdminProtectedPath.ANALYTIC_PLAYER.key
      ),
    ]),
  ];

  const handleOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Sider
      style={{ background: colorBgContainer }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className={cx('sidebar_container')}
    >
      <div className={cx('adminLayout_logo')}>
        <Link to='/'>
          {collapsed ? (
            <img src={icon.logoSideBar} alt={t('logo')} height={48} />
          ) : (
            <img src={icon.logoSideBar} alt={t('logo')} height={48} />
          )}
        </Link>
      </div>
      <Menu
        mode='inline'
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        defaultSelectedKeys={[getSideNav(locations).selectedKey]}
        items={items}
      />
    </Sider>
  );
};

export default SideBar;
