import { createAsyncThunk } from '@reduxjs/toolkit';
import teamApi, { IQueryParamGetTeams } from '~/apis/team';
import { TEAM_GET } from '~/utils/actionType';

export const getTeam = createAsyncThunk(TEAM_GET, async (payload: IQueryParamGetTeams, { rejectWithValue }) => {
  try {
    const response = await teamApi.getTeam(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
