import { urlApiAuth } from '~/utils/constants';
import axiosClient from './axiosClient';

interface ParamType {
    id: string;
}

const refreshTokenApi = {
    getRefreshToken(params: ParamType) {
        const url = `${urlApiAuth.refreshToken}`;
        return axiosClient.post(url, params);
    },
};

export default refreshTokenApi;
