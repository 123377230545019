import BackgroundLogin from '~/assets/imgs/login_image.jpeg';
import LogoSideBar from '~/assets/icons/logo_fife.png';

export const image = {
  backgroundLogin: BackgroundLogin,
};

export const icon = {
  logoSideBar: LogoSideBar,
};
