import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import authReducer from '~/thunks/auth/authSlice';
import detailMatchReducer from '~/thunks/match/matchSlice';
import roundReducer from '~/thunks/round/roundSlice';
import tournamentReducer from '~/thunks/tournament/tournamentSlice';
import tableReducer from './../thunks/table/tableSlice';
import totalPointTeamReducer from '../thunks/totalPointTeam/totalPointTeamSlice';
import teamReducer from './../thunks/team/teamSlice';
import totalPointPlayerReducer from './../thunks/totalPointPlayer/totalPointPlayerSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  tournament: tournamentReducer,
  round: roundReducer,
  detailMatch: detailMatchReducer,
  table: tableReducer,
  totalPointTeam: totalPointTeamReducer,
  team: teamReducer,
  analyticPlayer: totalPointPlayerReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
