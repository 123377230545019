import classNames from 'classnames/bind';

import { Layout, theme } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';
import HeaderLayout from '~/components/header/Header';
import SideBar from '~/components/sidebar/SideBar';
import styles from './AdminPanel.module.scss';
import packageJson from '../../../package.json';
const cx = classNames.bind(styles);

const AdminPanel = () => {
  const currentYear = new Date().getFullYear();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className={cx('adminLayout_container')}>
      <SideBar />
      <Layout className={cx('adminLayout_info')}>
        <HeaderLayout />
        <Content className={cx('adminLayout_content')}>
          <div style={{ background: colorBgContainer }} className={cx('adminLayout_content_detail')}>
            <Outlet />
          </div>
        </Content>
        <Footer className={cx('adminFooter')}>
          <div>&copy; {currentYear} FIFE. All rights reserved.</div>
          <div className={cx('version')}>{`Version ${packageJson.version}`}</div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminPanel;
