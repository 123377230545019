import { createSlice } from '@reduxjs/toolkit';
import { getTable } from './tableThunck';

interface ITableState {
  listRankingTeams: Array<any> | null | undefined;
}

const initialState: ITableState = {
  listRankingTeams: [],
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTable.fulfilled, (state, action) => {
      state.listRankingTeams = action.payload.data.rankingTeams;
    });
  },
});

export const tableActions = tableSlice.actions;

export const selectListTable = (state: any) => state.table.listRankingTeams;

const tableReducer = tableSlice.reducer;
export default tableReducer;
