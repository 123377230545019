import { createSlice } from '@reduxjs/toolkit';

import { login } from '~/thunks/auth/authThunk';
import { StorageConstants } from '~/utils/enum';

export interface AuthState {
  accessToken: string | null | undefined;
  userLogin: string | null | undefined;
}

const initialState: AuthState = {
  accessToken: localStorage.getItem(StorageConstants.ACCESS_TOKEN)! || null,
  userLogin: localStorage.getItem(StorageConstants.USER)! || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogout(state) {
      localStorage.removeItem(StorageConstants.ACCESS_TOKEN);
      localStorage.removeItem(StorageConstants.USER);
      state.accessToken = null;
      state.userLogin = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload?.data?.accessToken;
        state.userLogin = action.payload?.data?.user;
      })
      .addCase(login.rejected, (state, action) => {});
  },
});

export const authActions = authSlice.actions;
export const selectAccessToken = (state: any) => state.auth.accessToken;
const authReducer = authSlice.reducer;
export default authReducer;
