import { message } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { codeResponseApi } from './constants';
import { AdminProtectedPath } from './enum';

export const getSideNav = (list: string[]) => {
  if (list.length <= 2) {
    return AdminProtectedPath.ROUND;
  } else {
    if (!list[2]) {
      return AdminProtectedPath.ROUND;
    } else {
      const foundKey = Object.keys(AdminProtectedPath).find(
        (key) =>
          AdminProtectedPath[key as keyof typeof AdminProtectedPath]?.key === list[list.length - 1]
      );

      return AdminProtectedPath[foundKey as keyof typeof AdminProtectedPath];
    }
  }
};

export const validateEmail = (t: any): Rule => ({
  validator: (_, value, callback) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || value.match(emailRegex)) {
      callback();
    } else {
      callback(t('message_invalid_email'));
    }
  },
});

export const handleResponseError = (err: any, msg: string, t: any) => {
  if (err?.code === codeResponseApi.errNetwork) {
    message.error(err?.message);
    return;
  }

  message.error(t(msg));
};

export const convertToDD_MM_YY = (date: any) => {
  if (date) {
    const dateObj = new Date(date);

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  return '';
};

export const convertUpdated = (date: string) => {
  if (date) {
    const dateObj = new Date(date);

    const day = dateObj?.getDate().toString().padStart(2, '0');
    const month = (dateObj?.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj?.getFullYear();

    const hours = dateObj?.getHours().toString().padStart(2, '0');
    const minutes = dateObj?.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj?.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }
  return '';
};

export const convertTactic = (value: string) => {
  const formattedTactic = value?.split('').join('-');
  return formattedTactic;
};

export const convertDataSourceLeague = (dataSourceLeagues: any) => {
  const dataSource = dataSourceLeagues?.map((data: any) => ({
    value: data?.id,
    label: data?.name + ' ' + data?.year,
  }));

  return dataSource;
};

export const convertDataSourceTeam = (dataSourceTeams: any) => {
  const dataSource = dataSourceTeams?.map((data: any) => ({ value: data?.id, label: data?.name }));
  return dataSource;
};

export const convertDataSourceRound = (dataSourceRounds: any) => {
  const dataSource = dataSourceRounds?.map((data: any) => ({
    value: data?.id,
    label: `Round ${data?.day}`,
  }));
  return dataSource;
};

export const objectIsEmpty = (obj: any): boolean => {
  let isEmpty = false;
  if (!obj) {
    return false;
  }
  if (Object.keys(obj).length === 0) {
    isEmpty = true;
  }
  return isEmpty;
};

export const getEnumValue = (enumName: any, memberName: string) => {
  const indexOf = Object.keys(enumName).indexOf(memberName);
  const value = Object.values(enumName)[indexOf];
  return value;
};
