import { urlApiRound } from '~/utils/constants';
import axiosClient from './axiosClient';

export interface IQueryGetRounds {
  tournamentId: number;
  groupName?: string | null;
}

const roundApi = {
  importFileExcel(formData: any) {
    const url = `${urlApiRound.import}`;
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getRound(payload: IQueryGetRounds) {
    const { tournamentId, groupName } = payload;
    let url = `${urlApiRound.get}=${tournamentId}`;
    if (groupName) {
      url += `&groupName=${groupName}`;
    }
    return axiosClient.get(url);
  },

  getRoundsSelection(params: any) {
    const url = `${urlApiRound.getSelection}=${params}`;
    return axiosClient.get(url);
  },
};

export default roundApi;
