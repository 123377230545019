import { Header } from 'antd/es/layout/layout';
import { useEffect, useLayoutEffect, useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Breadcrumb, Dropdown, MenuProps, Select, theme } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '~/thunks/auth/authSlice';
import { language } from '~/utils/constants';
import logoutIcon from '../../assets/icons/logOut.svg';
import FlagEngland from '../../assets/imgs/flagEngland.svg';
import FlagItaly from '../../assets/imgs/flagItaly.svg';
import styles from './Header.module.scss';
const cx = classNames.bind(styles);
const { Option } = Select;

interface IUser {
  email: string;
  language: string;
  role: string;
}

const HeaderLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<IUser>();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language.IT);
  const dataLanguages = [
    { code: 'en', name: 'English', flag: FlagEngland },
    { code: 'it', name: 'Italian', flag: FlagItaly },
  ];

  const breadCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname
      .split('/')
      .filter((item) => item)
      .slice(1);
    const pathnamesObj = pathnames.map((item) => {
      return { title: t(`bread_crumb_${item}`) };
    });
    return <Breadcrumb items={pathnamesObj} />;
  };

  useLayoutEffect(() => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      setUser(JSON.parse(userStr));
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const items: MenuProps['items'] = [
    {
      label: <div>{t('hello', { email: user?.email ? user?.email : 'Admin' })}</div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div className={cx('header_logout')}>
          <img className={cx('header_logout-icon')} alt='logout' src={logoutIcon} /> {t('logout')}
        </div>
      ),
      key: 'logout',
    },
  ];

  const handleLogout = () => {
    dispatch(authActions.handleLogout());
    navigate('/login');
  };
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      setOpen(false);
      handleLogout();
    }
  };
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleChangeLanguage = (language: string) => {
    setSelectedLanguage(language);
  };

  return (
    <Header style={{ background: colorBgContainer }} className={cx('adminHeader')}>
      <div>{breadCrumbView()}</div>
      <div className={cx('header_action')}>
        <div className={cx('header_language')}>
          <Select
            onChange={(language) => handleChangeLanguage(language)}
            className={cx('language_select')}
            value={selectedLanguage}
            bordered={false}
            showArrow={false}
          >
            {dataLanguages.map((language) => (
              <Option key={language.code} value={language.code}>
                <div className={cx('language_item')}>
                  <img src={language.flag} className={cx('language_img')} alt='' /> {language.name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <Dropdown
          menu={{
            items,
            onClick: handleMenuClick,
          }}
          onOpenChange={handleOpenChange}
          open={open}
          trigger={['click']}
          overlayClassName={cx('dropdown-container')}
        >
          <a onClick={(e) => e.preventDefault()} className={cx('header_dropdown')}>
            <SettingOutlined className={cx('header_dropdown-icon')} />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default HeaderLayout;
