// Libs
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// Components
import { ProtectedRoute } from '~/components/common';
import NotFoundPage from '~/components/common/notFound/NotFound';
import { Login, PublicGroup, PublicTable } from '~/pages';
// Shares
import { AdminProtectedPath, RouterPaths } from '~/utils/enum';
import AdminPanel from './layouts/adminPanel/AdminPanel';
import { useAppSelector } from './redux/hooks';
import { selectAccessToken } from './thunks/auth/authSlice';
import { panelRouters } from './utils/routers/admin/routers';

type Props = {};

const App = (props: Props) => {
  const accessToken = useAppSelector(selectAccessToken);

  const handleRenderComponentLogin = () => {
    if (accessToken) {
      return <Navigate to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ROUND.path} replace />;
    }
    return <Login />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RouterPaths.MAIN} element={<Navigate to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ROUND.path} replace />} />
        <Route path={RouterPaths.ADMIN} element={<Navigate to={'/' + RouterPaths.ADMIN + AdminProtectedPath.ROUND.path} replace />} />
        <Route path={RouterPaths.ADMIN} element={<ProtectedRoute ComponentProtected={AdminPanel} />}>
          {panelRouters.map((item) => (
            <Route key={item.path} path={item.layout + item.path} element={<ProtectedRoute ComponentProtected={item?.component} />} />
          ))}
        </Route>
        <Route path={RouterPaths.LOGIN} element={handleRenderComponentLogin()} />
        <Route path={RouterPaths.PUBLIC.GROUP} element={<PublicGroup />} />
        <Route path={RouterPaths.PUBLIC.TABLE} element={<PublicTable />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
