import { createSlice } from '@reduxjs/toolkit';
import { getDetailMatch } from './matchThunk';

interface MatchState {
  detailMatch: any | null | undefined;
}

const initialState: MatchState = {
  detailMatch: {},
};

const detailMatchSlice = createSlice({
  name: 'detailMatch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDetailMatch.pending, (state, action) => {
      state.detailMatch = {};
    });
    builder.addCase(getDetailMatch.fulfilled, (state, action) => {
      state.detailMatch = action.payload.data.match;
    });
  },
});

export const detailMatchActions = detailMatchSlice.actions;

export const selectDetailMatch = (state: any) => state.detailMatch.detailMatch;

const detailMatchReducer = detailMatchSlice.reducer;
export default detailMatchReducer;
