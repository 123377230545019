// Local Storage
export const StorageConstants = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  USER: 'user',
  STATUS_LOGIN: 'statusLogin',
};

// Role
export const Roles = {
  ADMIN: 'ADMIN',
};

// Router && Path
export const RouterPaths = {
  MAIN: '/',
  LOGIN: 'login',
  ADMIN: 'admin',
  PUBLIC: {
    GROUP: 'public/groups',
    TABLE: 'public/tables',
  },
};

export const AdminProtectedPath = {
  ROUND: { path: '/groups', key: 'groups', title: 'round_title', selectedKey: 'groups' },
  TABLE: { path: '/tables', key: 'tables', title: 'table_title', selectedKey: 'tables' },
  MATCH_DETAIL: {
    path: '/groups/match',
    key: 'match',
    title: 'detail_match_title',
    selectedKey: 'groups',
  },
  ANALYTIC_PLAYER: {
    path: '/analytics/players',
    key: 'players',
    title: 'sidebar_player_title',
    selectedKey: 'players',
  },
  ANALYTIC_TEAM: {
    path: '/analytics/teams',
    key: 'teams',
    title: 'sidebar_team_title',
    selectedKey: 'teams',
  },
};

export const ExactPath = {
  main: `/${RouterPaths?.ADMIN}`,
  auth: {
    login: `/${RouterPaths.LOGIN}`,
  },
};

export const MAX_SIZE_IMPORT_FILE_EXCEL = 2 * 1024 * 1024;

export const TYPES_OF_EXCEL = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const BENCH_HEADER = 'bench';

export const REWARD_POINTS = [
  {
    key: 'defenseModifierPoint',
    label: 'match_defense_modifier',
  },
  {
    key: 'midfieldModifierPoint',
    label: 'match_midfield_modifier',
  },
  {
    key: 'captainModifierPoint',
    label: 'match_captain_modifier',
  },
  {
    key: 'officeReservePoint',
    label: 'match_office_reserve',
  },
  {
    key: 'totalPoint',
    label: 'match_total_point',
  },
];

export enum COLOR_GROUP {
  A = '#990000',
  B = '#003300',
  C = '#000066',
  D = '#ffd966',
}
