import { createAsyncThunk } from '@reduxjs/toolkit';
import tournamentApi from '~/apis/tournament';
import { TOURNAMENT_GET } from '~/utils/actionType';

export const getTournament = createAsyncThunk(TOURNAMENT_GET, async (_, { rejectWithValue }) => {
  try {
    const response = await tournamentApi.getTournament();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
