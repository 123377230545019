import { createSlice } from '@reduxjs/toolkit';
import { getTournament } from './tournamentThunk';

interface TournamentState {
  listTournament: Array<any> | null | undefined;
}

const initialState: TournamentState = {
  listTournament: [],
};

const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTournament.fulfilled, (state, action) => {
      state.listTournament = action.payload.data.tournament;
    });
  },
});

export const tournamentActions = tournamentSlice.actions;

export const selectListTournament = (state: any) => state.tournament.listTournament;

const tournamentReducer = tournamentSlice.reducer;
export default tournamentReducer;
